<template>
  <k-basic-modal-layout>
    <template v-slot:header>
      <div class="panel-header">
        <div class="d-flex align-items-center pr-20">
          <span class="title-border bg-blue-cyan mr-20"></span>
          <h1 class="hasab-title">Cikk előnézete</h1>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="px-25">
        <div class="page news-preview">
          <b-container>
            <b-row>
              <b-col cols="12">
                <iframe
                  class="iframe-elonezet"
                  :src="iframeSrc"
                  frameborder="0"
                ></iframe>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="footer justify-content-end">
        <k-button
          variant="dark"
          label="Bezárás"
          @click="Close()"
          icon="fas fa-times"
        ></k-button>
      </div>
    </template>
  </k-basic-modal-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import settings from '../../settings';
import { timeout } from '../../utils/common';
import { getToken } from '../../utils/token';

export default {
  name: 'cikk-elonezet-modal',
  data() {
    return { token: getToken() };
  },
  async mounted() {
    this.$emit('set-loading', true);
    await timeout(1000);
    this.$emit('set-loading', false);
  },
  created() {},
  methods: {
    Close() {
      this.$emit('close');
    },
  },
  computed: {
    ...mapGetters({}),
    iframeSrc() {
      return `${settings.prodBaseUrl}#/hir/${this.modal.data.articleId}?bottomBar=true&token=${this.token}`;
    },
  },
  watch: {},
  props: {
    modal: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.iframe-elonezet {
  width: 100%;
  min-height: 600px;
}
</style>
