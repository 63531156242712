import { render, staticRenderFns } from "./CikkElonezetModal.vue?vue&type=template&id=09b7d70e&scoped=true&"
import script from "./CikkElonezetModal.vue?vue&type=script&lang=js&"
export * from "./CikkElonezetModal.vue?vue&type=script&lang=js&"
import style0 from "./CikkElonezetModal.vue?vue&type=style&index=0&id=09b7d70e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09b7d70e",
  null
  
)

export default component.exports